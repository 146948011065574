import { render, staticRenderFns } from "./TextBox.vue?vue&type=template&id=1dd9cc98&scoped=true"
import script from "./TextBox.vue?vue&type=script&lang=js"
export * from "./TextBox.vue?vue&type=script&lang=js"
import style0 from "./TextBox.vue?vue&type=style&index=0&id=1dd9cc98&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd9cc98",
  null
  
)

/* custom blocks */
import block0 from "./TextBox.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports