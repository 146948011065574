var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea",class:{ 'form-group--error': _vm.validator && _vm.validator.$error }},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('textarea',{ref:"textarea",staticClass:"ez-slim-scroll",style:({
                  '--bg': _vm.bg,
                  '--width': _vm.width,
                  '--height': _vm.height,
                  '--border': _vm.border,
                  '--border-color': _vm.borderColor,
                  '--border-radius': _vm.borderRadius,
                  '--padding': _vm.padding,
                  '--box-shadow': _vm.boxShadow,
                  '--font-size': _vm.fSize,
                  '--font-color': _vm.fColor,
                  '--placeholder-color': _vm.phColor,
                  '--placeholder-size': _vm.phFSize,
              }),attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.updateText,"paste":_vm.handlePaste}}),(_vm.error)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),(_vm.validator && _vm.validator.$error)?_c('div',[(!_vm.validator.required)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t('field required')))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }